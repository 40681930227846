import React from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { sortStudentsByLevel } from '../AppManager';
import useAuthRedirect from '../components/AuthRedirect';

export default function Statistics() {
    const [stats, setStats] = React.useState({ "100": [], "200": [], "300": [], "400": [], "500": [] });
    const { data } = useOutletContext();

    useAuthRedirect();

    React.useEffect(() => {
        setStats(sortStudentsByLevel(data));
    }, [data]);

    return (
        <div className='statistics'>
            <div>
                <strong>
                    <span className='level'><Link to="total">Total</Link></span>
                </strong>
                <span>{ stats[100].length + stats[200].length + stats[300].length + stats[400].length + stats[500].length }</span>
            </div>
            {stats[100].length !== 0 && <div>
                <strong>
                    <span className="level"><Link to="100">100</Link></span>
                </strong>
                <span>{stats[100].length}</span>
            </div>}
            {stats[200].length !== 0 && <div>
                <strong>
                    <span className="level"><Link to="200">200</Link></span>
                </strong>
                <span>{stats[200].length}</span>
            </div>}
            {stats[300].length !== 0 && <div>
                <strong>
                    <span className="level"><Link to="300">300</Link></span>
                </strong>
                <span>{stats[300].length}</span>
            </div>}
            {stats[400].length !== 0 && <div>
                <strong>
                    <span className="level"><Link to="400">400</Link></span>
                </strong>
                <span>{stats[400].length}</span>
            </div>}
            {stats[500].length !== 0 && <div>
                <strong>
                    <span className="level"><Link to="500">500</Link></span>
                </strong>
                <span>{stats[500].length}</span>
            </div>}
        </div>
    )
}
