import React from 'react'
import { FaIdCard } from 'react-icons/fa6'
import { useOutletContext } from 'react-router-dom';
import { deleteRecordField, extractMatricDigits, showSwal } from '../AppManager';
import useAuthRedirect from '../components/AuthRedirect';

export default function Remove() {
    const { data, setData, info } = useOutletContext();
    const [formData, setFormData] = React.useState({matric: ""});
    const [loading, setLoading] = React.useState(false);

    useAuthRedirect();

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(old => ({ ...old, [name]: value }));
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            const matric = extractMatricDigits(formData.matric);
            if (Object.hasOwnProperty.call(data, matric)) {
                const remover = await deleteRecordField(info?.current?.session, matric);
                if(remover === true){
                    const clone = {};
                    for (const key in data) {
                        if (Object.hasOwnProperty.call(data, key)) {
                            if(key !== matric){
                                clone[key] = data[key];
                            }
                        }
                    }
                    setData(clone);
                    showSwal("success", "Record Removed");
                }else{
                    showSwal("error", "No Record Found");
                }
            } else {
                showSwal("error", "No Record Found");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            showSwal("error", "No Record Found");
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{marginTop: "10px"}}>
                <FaIdCard />
                <input type="text" autocomplete="off" name="matric" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RuN)/(CPE|CPe|Cpe|cPE|cpE|cpe|cPe|CpE)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' className="input" maxlength="16" minlength="15" placeholder="Matric Number" value={formData.matric} onChange={handleChange} style={{ textTransform: "uppercase" }} />
            </div>
            <input type="submit" value="remove" disabled={loading} className="button" />
        </form>
    )
}
