import React from 'react';
import "./ErrorTemplate.css";

function ErrorTemplate({ code, text, id }) {
    React.useEffect(() => {
        var flickerInterval;
        var Application = ( function () {
            var canvas;
            var ctx;
            var imgData;
            var pix;
            var WIDTH;
            var HEIGHT;
        
            var init = function () {
                canvas = document.getElementById('canvas');
                ctx = canvas.getContext('2d');
                canvas.width = WIDTH = 700;
                canvas.height = HEIGHT = 500;
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, WIDTH, HEIGHT);
                ctx.fill();
                imgData = ctx.getImageData(0, 0, WIDTH, HEIGHT);
                pix = imgData.data;
                flickerInterval = setInterval(flickering, 30);
            };
        
            var flickering = function () {
                for (var i = 0; i < pix.length; i += 4) {
                    var color = (Math.random() * 255) + 50;
                    pix[i] = color;
                    pix[i + 1] = color;
                    pix[i + 2] = color;
                }
                ctx.putImageData(imgData, 0, 0);
            };
        
            return {
                init: init
            };
        }());
        
        Application.init();

        return () => {
            clearInterval(flickerInterval);
        }
    }, []);

    React.useEffect(() => {
        const bars = document.getElementById("baroption");
        bars.classList.add("throbble");
    }, []);

    return (
        <section className='notfound' id={ id ? id : "error-page" }>
            <h1>{ code }</h1>
            <h3>{ text }</h3>

            <div className="frame">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="caps"><img src="http://ademilter.com/caps.png" alt="Hello" /></div>
            <canvas id="canvas"></canvas>
        </section>
    );
}

export default ErrorTemplate;