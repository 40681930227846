import React from 'react'
import { FaCoins } from 'react-icons/fa6'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { amountFormatter, getAcademicYear, showSwal, updateRecordField } from '../../AppManager';
import useAuthRedirect from '../../components/AuthRedirect';

export default function Fee() {
    const { info, setInfo } = useOutletContext();
    const [formData, setFormData] = React.useState({amount: "", siwes: ""});
    const [loading, setLoading] = React.useState(false);
    const session = getAcademicYear();
    const navigate = useNavigate();

    useAuthRedirect();

    React.useEffect(() => {
        if(info?.current?.session !== session){
            showSwal("error", "Session not Found");
            navigate("/info");
        }
    }, [info, session, navigate]);
    

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(old => ({ ...old, [name]: value }));
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            if (!Object.hasOwnProperty.call(info, session)) {
                showSwal("error", "Session not Found");
                setLoading(false);
            }else{
                const amount = formData.amount;
                const siwes = formData.siwes;
                if(parseInt(amount) !== 0 && parseInt(siwes) !== 0){
                    if(parseInt(amount) < parseInt(siwes)){
                        showSwal("error", "SIWES Fee Exceeded");
                    }else{
                        const updateSess = { ...info[session], amount, siwes };
                        const updater = await updateRecordField("sessions", session, updateSess);
                        const updater2 = await updateRecordField("sessions", "current", updateSess);

                        if(updater === true && updater2 === true){
                            setInfo(old => ({ ...old, [session]: updateSess, current: updateSess }));
                            showSwal("success", "Departmental Fee Updated");
                            setFormData({amount: "", siwes: ""});
                        }else{
                            showSwal("error", "An Error Occured");
                        }
                    }
                }else{
                    showSwal("error", "Fee cannot be ₦0");
                }
                setLoading(false);
            }
        } catch (error) {
            showSwal("error", "An Error Occured");
            setLoading(false);
        }
    }

    return (
        <form onSubmit={handleSubmit} className='updateform'>
            <div>
                <p><strong>Fee: </strong><span> { amountFormatter(info[session]?.amount ? info[session]?.amount : "0") }</span></p>
                <p><strong>SIWES: </strong><span> { amountFormatter(info[session]?.siwes ? info[session]?.siwes : "0") }</span></p>
            </div>
            <div>
                <FaCoins />
                <input type="text" autoComplete="off" name="amount" className="input" maxLength="8" minLength="4" placeholder="Amount" onChange={handleChange} value={formData.amount} pattern='^\d+(\.\d+)?$' required />
            </div>
            <div>
                <FaCoins />
                <input type="text" autoComplete="off" name="siwes" className="input" maxLength="8" minLength="4" placeholder="SIWES" onChange={handleChange} value={formData.siwes} pattern='^\d+(\.\d+)?$' required />
            </div>
            <input type="submit" value="submit" disabled={loading} className="button" />    
        </form>
    )
}
