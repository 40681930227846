import React from 'react'
import { FaSignature, FaUser } from 'react-icons/fa6'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { capitalizeEachWord, checkImage, deleteFiles, getAcademicYear, showSwal, updateRecordField, uploadFiles } from '../../AppManager';
import { nanoid } from "nanoid";
import useAuthRedirect from '../../components/AuthRedirect';

export default function Post() {
    const { post } = useParams();
    const { info, setInfo } = useOutletContext();
    const [formData, setFormData] = React.useState({name: "", image: ""});
    const [loading, setLoading] = React.useState(false);
    const session = getAcademicYear();
    const navigate = useNavigate();

    useAuthRedirect();

    React.useEffect(() => {
        if(info?.current?.session !== session){
            showSwal("error", "Session not Found");
            navigate("/info");
        }
    }, [info, session, navigate]);

    function handleChange(e){
        const { name, value, type, files } = e.target;
        if(type === "file" && checkImage(files, 50)){
            setFormData(oldData => ({...oldData, [name]: files }));
        }else{
            setFormData(oldData => ({...oldData, [name]: capitalizeEachWord(value) }));
        }
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            if (!Object.hasOwnProperty.call(info, session)) {
                showSwal("error", "Session not Found");
                setLoading(false);
            }else{
                let { name, image } = formData;
                if(image && image.length !== 0 && checkImage(image, 50)){
                    await deleteFiles(info[session]?.[post]?.image, "");
                    const uploadPreviews = await uploadFiles("", image, [nanoid()], false, false);
                    const preview = [];
                    for (let u = 0; u < uploadPreviews.length; u++) {
                        const { downloadURL } = await uploadPreviews[u];
                        preview.push(downloadURL);
                    }
                    image = preview[0];
                }
                image = image ? image : info[session]?.[post]?.image;
                const updateSess = { ...info[session], [post]: { name, image } };

                const updater = await updateRecordField("sessions", session, updateSess);
                const updater2 = await updateRecordField("sessions", "current", updateSess);

                if(updater === true && updater2 === true){
                    setInfo(old => ({ ...old, [session]: updateSess, current: updateSess }));
                    showSwal("success", `Info. Updated`);
                    setFormData({name: "", image: ""});
                }else{
                    showSwal("error", "Update Failed");
                }
                setLoading(false);
            }
        } catch (error) {
            showSwal("error", "Update Failed");
            setLoading(false);
        }
    }

    return (
        <form onSubmit={handleSubmit} className='updateform'>
            <div>
                <p><strong>Name: </strong><span> { info[session]?.[post]?.name ? info[session]?.[post]?.name : "" }</span></p>
                <p style={{ backgroundColor: "white", width: "220px", height: "50px", marginTop: "3px", display: "flex", justifyContent: "center" }}><img onError={(e)=>{ if (e.target.src !== "/blank.png"){ e.target.onerror = null; e.target.src="/blank.png"; }}}  src={ info[session]?.[post]?.image } alt={ info[session]?.[post]?.name } style={{ objectFit: "contain" }} /></p>
            </div>
            <div>
                <FaUser />
                <input type="text" autoComplete="off" name="name" className="input" maxLength="30" minLength="7" placeholder="Name" onChange={handleChange} value={formData.name} required />
            </div>
            <div>
                <FaSignature />
                <input type="file" name="image" className="input" onChange={handleChange} />
            </div>
            <input type="submit" value="submit" disabled={loading} className="button" />    
        </form>
    )
}
