import React from 'react';
import { FaEnvelope, FaSpinner } from 'react-icons/fa6';
import { passwordReset } from '../Auth';
import { useNavigate } from 'react-router-dom';
import { showSwal } from '../AppManager';

export default function Forgot() {
    const [formData, setFormData] = React.useState({ email: "" });
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(old => ({...old, [name]: value}));
    }

    function success(){
        showSwal("success", "Reset Link Sent");
        setLoading("done");
        setTimeout(() => {
            navigate("/admin");
        }, 2000);
    }

    function handleSubmit(e){
        e.preventDefault();
        setLoading("loading");
        passwordReset(formData.email)
        .then(data => {
            if(data.message === "Reset Link Sent"){
                success();
            }else if(data.message === "Firebase: Error (auth/user-not-found)."){
                showSwal("error", "Request Failed", "E-Mail is not registered")
                setLoading("failed");
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <div>
                    <input readOnly={loading} type={"email"} name="email" className='input' required placeholder="E-Mail..." maxLength={50} minLength={10} value={formData.email} onChange={handleChange} autoComplete="off" />
                    <FaEnvelope className='password-icon' />
                </div>
                <button type="submit" className='button' disabled={loading}>
                    {loading ? <FaSpinner className='spinner' /> : "send"}
                </button>
            </div>
        </form>
    );
}