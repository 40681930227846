import React from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { createExcelFormat, generateExcel, showSwal, sortDataDownload, sortStudentsByLevel } from '../AppManager';
import useAuthRedirect from '../components/AuthRedirect';

export default function StatsBreakdown() {
    const [stats, setStats] = React.useState({ "All": [], "Complete": [], "Incomplete": [] });
    const { data, info } = useOutletContext();
    const { breakdown } = useParams();
    const navigate = useNavigate();

    useAuthRedirect();
    
    React.useEffect(() => {
        let sort = [];
        if(breakdown === "total"){
            sort = sortDataDownload(data);
        }else{
            sort = sortStudentsByLevel(data);
            if (Object.hasOwnProperty.call(sort, breakdown)) {
                sort = sortStudentsByLevel(data)[breakdown];
            }else{
                sort = [];
                navigate("/statistics");
            }
        }
        const Complete = sort.filter(data => data.status === "Complete");
        const Incomplete = sort.filter(data => data.status === "Incomplete");
        setStats({ All: sort, Complete, Incomplete });
    }, [data, breakdown, navigate]);

    async function download(part){
        const session = info.current.session;
        let data = part !== "Complete" && part !== "Incomplete" ? stats.All : stats[part];
        if(data.length === 0){
            showSwal("error", "No Record Found");
            return;
        }
        data = createExcelFormat(data);
        const breaker = breakdown === "total" ? "" : ` ${breakdown} Level`;
        const partly = part !== "Complete" && part !== "Incomplete" ? "" : ` [${part}]`;
        generateExcel(data, `RUNACES${breaker}${partly} ${session.replace("/", "-")} Departmental Dues Payment Record`);
    }

    return (
        <div className='statistics'>
            <div style={{textAlign: "center", width: "100%"}}><strong style={{width: "100%"}}><span className="level" onClick={ () => { download("Total") } } >{ breakdown === "total" ? "Total" : `${breakdown} Level`}</span></strong></div>
            <div>
                <strong>
                    <span className="level" onClick={ () => { download("Complete") } }>Complete</span>
                </strong>
                <span>{stats.Complete.length}</span>
            </div>
            <div>
                <strong>
                    <span className="level" onClick={ () => { download("Incomplete") } }>Incomplete</span>
                </strong>
                <span>{stats.Incomplete.length}</span>
            </div>
        </div>
    )
}
