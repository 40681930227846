import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { authRequired } from '../Auth';

const useAuthRedirect = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;

    React.useEffect(() => {
        authRequired()
        .then(admin => {
            if (!admin) {
            navigate(`/forbidden?redirectTo=${pathname}`);
            }
        })
        .catch(() => {
            navigate(`/forbidden?redirectTo=${pathname}`);
        });
    }, [navigate, pathname]);
};

export default useAuthRedirect;