import React from 'react';
import { FaEye, FaEyeSlash, FaSpinner } from 'react-icons/fa6';
import { signInUser } from '../Auth';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { showSwal } from '../AppManager';

function Login() {
    const [formData, setFormData] = React.useState({ email: "joshuataiwo07@gmail.com", password: "" });
    const [showp, setShowp] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const queryParams = useSearchParams()[0];
    const redirectTo = queryParams.get('redirectTo');

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(old => ({...old, [name]: value}));
    }

    async function handleSubmit(e){
        e.preventDefault();
        setLoading("loading");
        signInUser(formData.email, formData.password)
        .then(res => {
            if(res.email && res.emailVerified){
                showSwal("success", "Login Successful");
                setLoading("done");
                setTimeout(() => {
                    navigate( redirectTo ? redirectTo : "/" );
                }, 2000);
            }else if(res.message === "verification"){
                showSwal("error", "Login Failed", "E-Mail Verification Pending");
                setLoading("failed");
            }else if(res.message === "Firebase: Error (auth/user-not-found)." || res.message === "Firebase: Error (auth/wrong-password)." || res.message === "Firebase: Error (auth/invalid-login-credentials)."){
                showSwal("error", "Login Failed", "E-Mail / Password Mismatched");
                setLoading("failed");
            }else if(res.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."){
                showSwal("error", "Login Failed", "Account Temporarily Blocked. Reset Password for immediate access");
                setLoading("failed");
            }else{
                showSwal("error", "Login Failed");
                setLoading("failed");
            }

            if(!(res.email && res.emailVerified)){
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }
        })
        .catch((error) => {
            showSwal("error", "Login Failed");
            setLoading("failed");
        })
    }

    function toggleShow(){
        setShowp(oldShowp => !oldShowp);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <div>
                    <input readOnly={loading} type={showp ? "text" : "password"} name="password" className='input' required placeholder="Password..." maxLength={25} minLength={6} value={formData.password} onChange={handleChange} autoComplete="off" />
                    {!showp && <FaEye onClick={toggleShow} className='password-icon' />}
                    {showp && <FaEyeSlash onClick={toggleShow} className='password-icon' />}
                </div>
                <Link to="forgot" style={{fontSize: "0.8em"}}>Forgot Password?</Link>
                <button type="submit" className='button' disabled={loading}>
                    {loading ? <FaSpinner className='spinner' /> : "submit"}
                </button>
            </div>
        </form>
    );
}

export default Login;