import React from 'react';
import './Receipt.css';
import { amountFormatter, capitalizeEachWord, convertNumberToWords, getFormattedDate } from '../AppManager';

export default function Receipt({ session, name, matric, level, amount, president, pimg, secretary, simg, financial, fimg }) {
    return (
        <div className='receipt'>
            <div className="monitor">
                <div className="stand">
                    <div className="header">
                        <img src="/images/RUNACES LOGO.png" alt="RUNACES" />
                        <div>
                            <h3>Redeemer's University Association of Computer Engineering Student (RUNACES)</h3>
                            <i>...Excellence and Innovation Built into Every Design...</i>
                        </div>
                    </div>
                    <hr />
                    <h3 style={{ display: "inline" }}>{ session }</h3>
                    <h3 style={{ display: "inline" }}> Academic Session Departmental Due Receipt</h3>
                    <div className="details">
                        <div className="watermark">
                            <h2>RUNACES</h2>
                        </div>
                        <div className="form-box">
                            <label>Name</label>
                            <span>{ name }</span>
                        </div>
                        <div className="form-box half" style={{marginRight: "2%"}}>
                            <label>Matriculation Number</label>
                            <span>{ matric }</span>
                        </div>
                        <div className="form-box half">
                            <label>Level</label>
                            <span>{ level } Level</span>
                        </div>
                        <div className="form-box">
                            <label>Amount in Words</label>
                            <span>{ capitalizeEachWord(convertNumberToWords(amount)).replace("And", "and") } Naira Only</span>
                        </div>
                        <div className="form-box half" style={{marginRight: "2%"}}>
                            <label>Amount</label>
                            <span>{ amountFormatter(amount) }</span>
                        </div>
                        <div className="form-box half">
                            <label>Date</label>
                            <span>{ getFormattedDate() }</span>
                        </div>
                    </div>
                    <div className="signatures">
                        <div>
                            <div>
                                <img src={ pimg } alt="President" />
                            </div>
                            <span>
                                <strong>{ president }</strong>
                                <i>President</i>
                            </span>
                        </div>
                        <div>
                            <div>
                                <img src={ simg } alt="General Secretary" />
                            </div>
                            <span>
                                <strong>{ secretary }</strong>
                                <i>General Secretary</i>
                            </span>
                        </div>
                        <div>
                            <div>
                                <img src={ fimg } alt="Financial Secretary" />
                            </div>
                            <span>
                                <strong>{ financial }</strong>
                                <i>Financial Secretary</i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
