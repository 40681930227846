import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom'
import Navbar from './Navbar';
import Footer from './Footer';
import Preloader from './Preloader';
import { FaArrowLeft, FaArrowRight, FaBars } from 'react-icons/fa6';
import { getRecord } from '../AppManager';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../Api';
import { doc, onSnapshot } from 'firebase/firestore';

function Layout() {
    const [data, setData] = React.useState([]);
    const [info, setInfo] = React.useState({});
    const [preloader, setPreloader] = React.useState(true);
    const [admin, setAdmin] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const [flip, setFlip] = React.useState(false);
    const [heading, setHeading] = React.useState(false);
    const path = useLocation().pathname;

    React.useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user && user.email && user.emailVerified) {
                setAdmin(true);
            }else{
                setAdmin(false);
            }
        });
    }, []);
    
    React.useEffect(() => {
        document.getElementById("preloader") && setTimeout(() => {
            document.getElementById("preloader").style.opacity = 0;
            document.getElementById("preloader").style.pointerEvents = "none";
            setTimeout(() => {
                setPreloader(false);
            }, 1000);
        }, 1500);
    }, []);

    function flipped(){
        setCheck(oldCheck => !oldCheck);
        setTimeout(() => {
            setFlip(oldNav => !oldNav);
        }, 300);
    };

    React.useEffect(() => {
        const pages = ["forbidden", "financial", "secretary", "president", "fee", "info", "update", "add", "remove", "forgot", "admin", "statistics", "download"];
        const pageHead = {
            home: {
                title: "",
            },
            add: {
                title: "Add to Record",
            },
            update: {
                title: "Update Record",
            },
            remove: {
                title: "Remove from Record",
            },
            statistics: {
                title: "Statistics",
            },
            admin: {
                title: "Login",
            },
            forgot: {
                title: "Forgot Password",
            },
            download: {
                title: "Download Record",
            },
            info: {
                title: "Update Info.",
            },
            fee: {
                title: "Departmental Fee",
            },
            president: {
                title: "President Info.",
            },
            secretary: {
                title: "General Secretary Info.",
            },
            financial: {
                title: "Financial Secretary Info.",
            },
            forbidden: {
                title: "Missing Permissions",
            },
        }
        let currPage = "home";
        for (let i = 0; i < pages.length; i++) {
            const element = pages[i];
            if(path === "/"){
                currPage = "home";
                break;
            }else if(path === `/${element}` || path.search(`/${element}/`) > -1){
                currPage = element;
                break;
            }
        }
        const heading = pageHead[currPage].title;
        setHeading(heading);
    }, [path]);

    React.useEffect(() => {
        getRecord("sessions")
        .then(data => setInfo(data))
        .catch(err => err);

        onSnapshot(doc(db, "record", "sessions"), (doc) => {
            // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
            const record = doc.data();
            setInfo(record);
        });
    }, []);

    React.useEffect(() => {
        if(admin){
            getRecord(info?.current?.session)
            .then(data => setData(data))
            .catch(err => err);

            if(info?.current?.session){
                onSnapshot(
                    doc(db, "record", info?.current?.session), 
                    (doc) => {
                        // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                        const record = doc.data();
                        setData(record);
                    }, 
                    (err) => err
                );
            }
        }
    }, [admin, info]);

    return ( 
        <>
            {preloader && <Preloader />}
            <div className="pagewrapper">
                <main>
                    <div className="banner" id="banner">
                        <img src="/images/RUNACES Banner.png" alt="RUNACES Banner" />
                    </div>
                    <div className="titlewrapper">
                        <h3 className="title">
                            {heading}
                        </h3>
                        <h3 className="title title-notfound">
                            Page not Found
                        </h3>
                        <h3 className="title title-error">
                            An Error Occurred
                        </h3>
                    </div>
                    <div className="formwrapper">
                        <div className="monitor">
                            <div className="stand">
                                <input type="checkbox" id="flip" style={{display: "none"}} checked={check} />
                                <div className="form">
                                    <div id="logo">
                                        <img src="/images/RUNACES LOGO NO BG.png" alt="RUNACES Logo" />
                                    </div>
                                    {!flip && <label>
                                        <FaBars id="baroption" onClick={flipped} />
                                    </label>}
                                    {!flip && <Outlet context={{ data, setData, admin, info, setInfo }} />}
                                    {flip && <Navbar flipped={flipped} admin={admin} />}
                                    {!flip && <Link to={-1} className='back'><FaArrowLeft /></Link>}
                                    {admin && !flip && <Link to={+1} className='back front'><FaArrowRight /></Link>}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
}

export default Layout;