import React from 'react'
import { FaIdCard, FaSchool } from 'react-icons/fa6'
import { useOutletContext } from 'react-router-dom';
import { amountFormatter, extractMatricDigits, getRecord, showSwal } from '../AppManager';
import Swal from 'sweetalert2';
import { nanoid } from "nanoid";
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import Receipt from './Receipt';

export default function Home() {
    const { info } = useOutletContext();
    const [formData, setFormData] = React.useState({matric: "", session: ""});
    const [sessions, setSessions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        let sessions = [];
        for (const session in info) {
            if(session !== "current"){
                sessions.push(session);
            }
        }
        sessions = sessions.sort().reverse();
        setSessions(sessions);
    }, [info]);

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(old => ({ ...old, [name]: value }));
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            const session = formData.session;
            const data = await getRecord(session);
            const matric = extractMatricDigits(formData.matric);
            if (Object.hasOwnProperty.call(data, matric)) {
                const student = data[matric];
                const total = student.level === "400" ? info[session].siwes : info[session].amount;
                const debt = parseFloat(total) - parseFloat(student.paid);
                if(student.status === "Complete" && student.paid === total && debt === parseFloat(student.debt) && debt === 0){
                    Swal.fire({
                        icon: "success",
                        title: "Payment Completed",
                        text: "Click the Download button to get your Receipt",
                        showDenyButton: true,
                        confirmButtonColor: "red",
                        denyButtonColor: "#002F63",
                        denyButtonText: 'Download',
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        if (result.isDenied) {
                            const { president, secretary, financial } = info[session];
                            const printElement = ReactDOMServer.renderToString(<Receipt session={ session } name={ student.name } matric={ student.matric } level={ student.level } amount={ student.paid } president={ president.name } pimg={ president.image } secretary={ secretary.name } simg={ secretary.image } financial={ financial.name } fimg={ financial.image } />);
                            const options = {
                                margin: [0, 0],
                                filename: `${student.name} ${session.replace("/", "-")} RUNACES Departmental Due Receipt.pdf`,
                                jsPDF: {
                                    format: [200, 260],
                                    orientation: 'landscape',
                                    unit: 'mm'
                                }
                            };
                            html2pdf().set(options).from(printElement).save();
                        }
                    });
                    setFormData({matric: "", session: ""});
                }else if(student.status === "Incomplete" && debt === parseFloat(student.debt)){
                    showSwal("info", "Incomplete Payment", `You can complete your payment by transferring ${amountFormatter(student.debt)} to Zenith Bank: 1310925618 (RUN ASS. OF COMPUTER ENGINEERING)`);
                    setFormData({matric: "", session: ""});
                }else{
                    showSwal("error", "No Record Found");
                }
            }else{
                showSwal("error", "No Record Found");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            showSwal("error", "No Record Found");
        }
    }

    const sessionsElement = sessions.map(sess => <option key={ nanoid() } value={ sess }>{ sess }</option>);

    return (
        <form onSubmit={handleSubmit} className='home'>
            <div style={{marginTop: "10px"}}>
                <FaIdCard />
                <input type="text" autoComplete="off" name="matric" required className="input" maxLength="16" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RuN)/(CPE|CPe|Cpe|cPE|cpE|cpe|cPe|CpE)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/CPE/yy/yyyy' placeholder="Matric Number" value={formData.matric} onChange={handleChange} style={{ textTransform: "uppercase" }} />
            </div>
            <div>
                <FaSchool />
                <select name="session" value={formData.session} style={{ textTransform: "initial" }} className="input" onChange={handleChange} required>
                    <option disabled value="">Select Session</option>
                    { sessionsElement }
                </select>
            </div>
            <input type="submit" value="submit" disabled={loading} className="button" />
        </form>
    )
};