import React from 'react';
import { nanoid } from 'nanoid';
import { useOutletContext } from 'react-router-dom';
import { createExcelFormat, generateExcel, getRecord, showSwal, sortDataDownload } from '../AppManager';
import useAuthRedirect from '../components/AuthRedirect';

export default function Download() {
    const { info } = useOutletContext();
    const [data, setData] = React.useState([]);
    const [show, setShow] = React.useState(0);
    const [more, setMore] = React.useState(false);

    useAuthRedirect();

    React.useEffect(() => {
        let sessions = [];
        for (const session in info) {
            if(session !== "current"){
                sessions.push(session);
            }
        }
        sessions = sessions.sort().reverse();
        setData(sessions);
    }, [info]);

    React.useEffect(() => {
        if(data.length > 5){
            setShow(5);
            setMore(true);
        }else{
            setShow(data.length);
        }
    }, [data]);

    function moreShown(){
        setShow(oldShow => {
            let add = 5;
            if(data.length - oldShow < 5){
                add = data.length % 5;
                setMore(false);
            }else if(data.length - oldShow === 5){
                setMore(false);
            }
            return oldShow + add;
        });
    }

    async function download(session){
        let data = await getRecord(session);
        data = sortDataDownload(data);
        if(data.length === 0){
            showSwal("error", "No Record Found");
            return;
        }
        data = createExcelFormat(data);
        generateExcel(data, `RUNACES ${session.replace("/", "-")} Departmental Dues Payment Record`);
    }

    const display = data.length === 0 
    ? 
    <span style={{ padding: "50px 0" }}>No Record Found</span> 
    : 
    data.slice(0, show).map(dat => <span onClick={() => { download(dat) }} key={nanoid()}>{dat}</span>)

    return (
        <div className='download'>
            <div>
                { display }
            </div>
            <span>
            {more && <label className='button' onClick={moreShown}>more</label>}
            </span>
        </div>
    )
}
