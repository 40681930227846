import React from 'react'
import { FaIdCard } from 'react-icons/fa6'
import { useNavigate, useOutletContext } from 'react-router-dom';
import { extractMatricDigits, showSwal } from '../AppManager';
import useAuthRedirect from '../components/AuthRedirect';

export default function Update() {
    const { data, info } = useOutletContext();
    const [formData, setFormData] = React.useState({matric: ""});
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    useAuthRedirect();

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(old => ({ ...old, [name]: value }))
    }

    function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            const matric = extractMatricDigits(formData.matric);
            if (Object.hasOwnProperty.call(data, matric)) {
                const student = data[matric];
                const total = student.level === "400" ? info.current.siwes : info.current.amount;
                const debt = parseFloat(total) - parseFloat(student.paid);
                if(debt !== 0){
                    navigate(matric);
                }else{
                    showSwal("info", "Payment Complete");
                }
            } else {
                showSwal("error", "No Record Found");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            showSwal("error", "No Record Found");
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{marginTop: "10px"}}>
                <FaIdCard />
                <input type="text" autocomplete="off" name="matric" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RuN)/(CPE|CPe|Cpe|cPE|cpE|cpe|cPe|CpE)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' className="input" maxlength="16" minlength="15" placeholder="Matric Number" style={{ textTransform: "uppercase" }} value={ formData.matric } onChange={ handleChange } required />
            </div>
            <input type="submit" value="retrieve" disabled={loading} className="button" />
        </form>
    )
}
