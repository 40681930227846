import React from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { getAcademicYear, showSwal, updateRecordField } from '../AppManager';
import useAuthRedirect from '../components/AuthRedirect';

export default function Info() {
    const { info, setInfo } = useOutletContext();
    const [data, setData] = React.useState([]);

    useAuthRedirect();

    React.useEffect(() => {
        let sessions = [];
        for (const session in info) {
            if(session !== "current"){
                sessions.push(session);
            }
        }
        sessions = sessions.sort().reverse();
        setData(sessions);
    }, [info]);

    async function newSession(){
        const sess = getAcademicYear();
        const sessions = Object.keys(data);
        if(sessions.length !== 0){
            if(sessions.includes(sess)){
                showSwal("error", "Session already Exists");
            }else{
                const newSess = {
                    "session": sess,
                    "amount": "",
                    "siwes": "",
                    "president": { "name": "", "image": "" },
                    "secretary": { "name": "", "image": "" },
                    "financial": { "name": "", "image": "" }
                }
                const ups = await updateRecordField("sessions", "current", newSess);
                const up = await updateRecordField("sessions", sess, newSess);
                if(up === true && ups === true){
                    setInfo(old => ({ ...old, current: newSess, [sess]: newSess }));
                    showSwal("success", "Session Started");
                }else{
                    showSwal("error", "Session Start Failed");
                }
            }
        }else{
            showSwal("error", "Session Start Failed");
        }
    }

    return (
        <div className='download'>
            <div>
                {!data.includes(getAcademicYear()) && <span onClick={newSession}>Start New Session</span>}
                {data.includes(getAcademicYear()) && <>
                    <span>
                        <Link to="fee">Departmental Fee</Link>
                    </span>
                    <span>
                        <Link to="president">President Info.</Link>
                    </span>
                    <span>
                        <Link to="secretary">General Secretary Info.</Link>
                    </span>
                    <span>
                        <Link to="financial">Financial Secretary Info.</Link>
                    </span>
                </>}
            </div>
        </div>
    )
}
