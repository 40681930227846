import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import Layout from './components/Layout';
import Home from './pages/Home';
import Error from './components/Error';
import NotFound from './components/NotFound';
import Add from './pages/Add';
import Remove from './pages/Remove';
import Update from './pages/Update';
import UpdateForm from './pages/UpdateForm';
import Statistics from './pages/Statistics';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import StatsBreakdown from './pages/StatsBreakdown';
import Download from './pages/Download';
import Info from './pages/Info';
import Fee from './pages/info/Fee';
import Post from './pages/info/Post';
import Forbidden from './components/Forbidden';

const transition = { duration: 0.5 };

const AnimatedRoute = ({ children }) => (
  <motion.div
    key={window.location.pathname}
    initial={{ opacity: 0, x: -100 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 100 }}
    transition={transition}
  >
    {children}
  </motion.div>
);

export const routes = [
  { path: '/', element: <Home />, errorElement: <Error /> },
  { path: 'admin', element: <Login /> },
  { path: 'admin/forgot', element: <Forgot /> },
  { path: 'add', element: <Add /> },
  { path: 'add/update', element: <Update /> },
  { path: 'add/update/:id', element: <UpdateForm /> },
  { path: 'remove', element: <Remove /> },
  { path: 'statistics', element: <Statistics /> },
  { path: 'statistics/:breakdown', element: <StatsBreakdown /> },
  { path: 'download', element: <Download /> },
  { path: 'info', element: <Info /> },
  { path: 'info/fee', element: <Fee /> },
  { path: 'info/:post', element: <Post /> },
  { path: 'forbidden', element: <Forbidden /> },
  { path: '*', element: <NotFound /> },
];

const routerElements = {
  path: '/',
  element: <Layout />,
  children: routes.map((route) => (route.childOf ? {} : {
    index: route.path === '/',
    path: route.path === '/' ? undefined : route.path,
    element: <AnimatedRoute>{route.element}</AnimatedRoute>,
    loader: route.loader ? route.loader : undefined,
    action: route.action ? route.action : undefined,
    errorElement: route.errorElement ? route.errorElement : <Error />,
    children: route.path === '/' ? undefined : routes.map((rout) => {
      if(rout.childOf === route.parent){
        return {
          index: rout.path === '/',
          path: rout.path === '/' ? undefined : rout.path,
          element: <AnimatedRoute>{rout.element}</AnimatedRoute>,
          loader: rout.loader ? rout.loader : undefined,
          action: rout.action ? rout.action : undefined,
          errorElement: rout.errorElement ? rout.errorElement : <Error />,
          children: rout.path === '/' ? undefined : routes.map((routs) => {
            if(routs.childOf === rout.parent){
              return {
                index: routs.path === '/',
                path: routs.path === '/' ? undefined : routs.path,
                element: <AnimatedRoute>{routs.element}</AnimatedRoute>,
                loader: routs.loader ? routs.loader : undefined,
                action: routs.action ? routs.action : undefined,
                errorElement: routs.errorElement ? routs.errorElement : <Error />
              }
            }else{
              return {};
            }
          })
        }
      }else{
        return {};
      }
    })
  })),
};

const router = createBrowserRouter([
  routerElements
]);

export default function App() {
  return (
    <div className='App'>
      <AnimatePresence mode="wait">
        <RouterProvider router={router} />
      </AnimatePresence>
    </div>
  );
}