import React from 'react';
import { FaChartBar, FaDownload, FaHouse, FaPen, FaUserMinus, FaUserPlus } from 'react-icons/fa6';
import { FaInfoCircle, FaSignInAlt, FaSignOutAlt, FaTimes } from 'react-icons/fa';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { signOutUser } from '../Auth';

function Navbar({ flipped, admin }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const queryParams = useSearchParams()[0];
    const redirectTo = queryParams.get('redirectTo');

    return (
        <nav className="navbarwrap">
            <ul>
                <li>
                    <NavLink className={({isActive}) => isActive ? "active" : ""} onClick={flipped} to="/" title="Home">
                        <FaHouse />
                    </NavLink>
                </li>
                <li>
                    {admin && <NavLink className={({isActive}) => isActive ? "active" : ""} onClick={flipped} to="/add" title="Add">
                        <FaUserPlus />
                    </NavLink>}
                    {!admin && <span></span>}
                </li>
                <li>
                    {admin && <NavLink className={({isActive}) => isActive ? "active" : ""} onClick={flipped} to="/remove" title="Remove">
                        <FaUserMinus />
                    </NavLink>}
                    {!admin && <span></span>}
                </li>
                <li>
                    {admin && <span title="Log Out" onClick={flipped}>
                        <FaSignOutAlt onClick={ () => { signOutUser(); navigate("/"); } } className='signout-icon' />
                    </span>}
                    {!admin && <NavLink className={({isActive}) => isActive ? "active" : ""} onClick={flipped} to={redirectTo ? `/admin?redirectTo=${redirectTo}` : "/admin" } title="Admin" id={pathname.indexOf("forbidden") !== -1 ? "forbidden-login" : ""}>
                        <FaSignInAlt />
                    </NavLink>}
                </li>
                <li>
                    {admin && <NavLink className={({isActive}) => isActive ? "active" : ""} onClick={flipped} to="/download" title="Download">
                        <FaDownload />
                    </NavLink>}
                    {!admin && <span></span>}
                </li>
                <li>
                    {admin && <NavLink className={({isActive}) => isActive ? "active" : ""} onClick={flipped} to="/statistics" title="Statistics">
                        <FaChartBar />
                    </NavLink>}
                    {!admin && <span></span>}
                </li>
                <li className="closeicon" title="Close">
                    <span>
                        <FaTimes onClick={flipped} />
                    </span>
                </li>
            </ul>
            {admin && <NavLink to="/info" onClick={flipped} className="info-icon"><FaInfoCircle /><FaPen className='pen-icon' /></NavLink>}
        </nav>
    );
}

export default Navbar;