import { deleteField, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import { db, storage } from "./Api";
import { authRequired } from "./Auth";
import * as XLSX from 'xlsx';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

export function stripSpecialChar(string) {
    const specialChars = ['\\', '/', '*', ':', '?', '"', '<', '>', '|', '&'];
    let newString = '';
    for (let k = 0; k < string.length; k++) {
      if (!specialChars.includes(string[k])) {
        newString += string[k];
      }
    }
    return newString;
}

function compareByProperty(a, b, property) {
    let ap = a[property];
    let bp = b[property];
    return typeof(ap) === "number" && typeof(bp) === "number" ? ap - bp : ap.localeCompare(bp);
};
  
export function sortByProperty(arr, property) {
    try {
        let array = arr.slice();
        return array.sort((a, b) => compareByProperty(a, b, property));
    } catch (error) {
        // console.log(error);        
    }
};

export function sortStudentsByLevel(data){
    let students = { "100": [], "200": [], "300": [], "400": [], "500": [] };
    for (const matric in data) {
        if (Object.hasOwnProperty.call(data, matric)) {
            const student = data[matric];
            student.id = matric;
            students[student.level].push(student);
        }
    }
    for (const key in students) {
        if (Object.hasOwnProperty.call(students, key)) {
            const element = students[key];
            students[key] = sortByProperty(element, "id");
        }
    }
    return students;
}

export function sortDataDownload(data){
    const levelSort = sortStudentsByLevel(data);
    const keys = Object.keys(levelSort).sort();
    let sorted = [];
    keys.map(key => {
        if (Object.hasOwnProperty.call(levelSort, key)) {
            const students = levelSort[key];
            sorted = sorted.concat(students);
        }
        return key;
    })
    return sorted;
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function amountFormatter(amount){
    let out = parseFloat(amount);
    out = out.toFixed(2).toString();
    out = numberWithCommas(out);
    return "₦" + out;
}

export function showSwal(icon, title, text, time){
    Swal.fire({
        icon: icon,
        title: title,
        text: text,
        confirmButtonText: 'OK',
        timer: time
    })
};

export function stripSpace(string) {
    let newString = '';
    for (let k = 0; k < string.length; k++) {
      if (string[k] !== " ") {
        newString += string[k];
      }
    }
    return newString;
}

export function extractMatricDigits(string){
    const i = string.lastIndexOf("/") + 1;
    if(i === 0){
        if(string.length > 5){
            return string.slice(string.length - 5);
        }else{
            return string;
        }
    }else{
        return string.slice(i);
    }
}

export function getAcademicYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    let academicYear;
    if (currentMonth >= 8) { 
        academicYear = `${currentYear} - ${currentYear + 1}`;
    } else {
        academicYear = `${currentYear - 1} - ${currentYear}`;
    }
    return academicYear;
}

export const capitalizeEachWord = (string) => {
    if(string){
        let words = string.split(" ");
        for (let k = 0; k < words.length; k++) {
            const word = words[k];
            if(word){
                words[k] = word[0].toUpperCase() + word.slice(1).toLowerCase();
            }
        }

        let newWord = "";
        for (let p = 0; p < words.length; p++) {
            const word = words[p];
            if(p === words.length - 1 || word !== ""){
                newWord += word + " ";
            }
        }
        return newWord.slice(0, newWord.length - 1);
    }
}

export function createExcelFormat(data){
    const output = [];
    for (let p = 0; p < data.length; p++) {
        const student = data[p];
        output.push({ "Matric": student.matric, "Name": student.name, "Level": student.level, "Paid": amountFormatter(student.paid), "Debt": amountFormatter(student.debt), "Status": student.status });
    }
    return output;
}

export function generateExcel(data, filename){
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
}

export function convertNumberToWords(num) {
    if (num === 0) return 'Zero';

    const belowTwenty = [
        'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
        'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const tens = [
        '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];
    const thousands = [
        '', 'Thousand', 'Million', 'Billion'
    ];

    function helper(n) {
        if (n === 0) return '';
        else if (n < 20) return belowTwenty[n - 1];
        else if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 ? '-' + belowTwenty[n % 10 - 1].toLowerCase() : '');
        else return belowTwenty[Math.floor(n / 100) - 1] + ' Hundred' + (n % 100 ? ' and ' + helper(n % 100).toLowerCase() : '');
    }

    let word = '';
    let i = 0;

    while (num > 0) {
        if (num % 1000 !== 0) {
            word = helper(num % 1000) + (thousands[i] ? ' ' + thousands[i] : '') + (word ? ', ' + word : '');
        }
        num = Math.floor(num / 1000);
        i++;
    }

    // Remove the trailing comma and space, then capitalize appropriately
    return word.replace(/,\s*$/, '').trim().replace(/\b\w/g, char => char.toUpperCase());
}

export function getFormattedDate() {
    const date = new Date();
    
    const day = date.getDate();
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    
    const ordinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // catch exceptions for 11th, 12th, 13th
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    };
    
    return `${day}${ordinalSuffix(day)} of ${month}, ${year}`;
}

export function checkExtension(string) {
    const extensions = ['jpeg', 'jpg', 'png', 'heic'];
    const ext = string.slice(string.lastIndexOf(".") + 1).toLowerCase();
    if(extensions.includes(ext)) {
        return true;
    }
    return false;
}

export function checkImage(files, maxSize){
    if(files.length > 5){
        showSwal("error", "Maximum of 5 Images");
        return "length";
    }
    const maximum = 1024 * maxSize;
    for (let e = 0; e < files.length; e++) {
        const file = files[e];
        if(file.size <= maximum && checkExtension(file.name)){
            return true;
        }else{
            if(file.size > maximum){
                showSwal("error", "Maximum File Size Exceeded");
                return "size";
            }else if(!checkExtension(file.name)){
                showSwal("error", "Invalid File Format");
                return "format";
            }
        }
    }
}


// ==========================================================================================

// Firebase

// ==========================================================================================
export const updateRecord = async (session, data) => {
    if(!await authRequired()){
        const error = {
            code: 401,
            message: "Access not Granted",
        }
        throw error;
    }
    try {
        const dataRef = doc(db, "record", session.replace("/", "-"));
        await setDoc(dataRef, data, { merge: true });
        return true;
    } catch (error) {
        return error;
    }
}

export const updateRecordField = async (session, id, data) => {
    if(!await authRequired()){
        const error = {
            code: 401,
            message: "Access not Granted",
        }
        throw error;
    }
    try {
        const dataRef = doc(db, "record", session.replace("/", "-"));
        await setDoc(dataRef, {[id]: data}, { merge: true });
        return true;
    } catch (error) {
        // console.log(error);
        return error;
    }
}

export const deleteRecordField = async (session, id) => {
    if(!await authRequired()){
        const error = {
            code: 401,
            message: "Access not Granted",
        }
        throw error;
    }
    try {
        const dataRef = doc(db, "record", session.replace("/", "-"));
        await updateDoc(dataRef, {[id]: deleteField()});
        return true;
    } catch (error) {
        return error;
    }
}

export const getRecord = async (session) => {
    let data = {};
    const docRef = doc(db, "record", session.replace("/", "-"));
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()){
        data = docSnap.data();
    }
    return data;
}

export const uploadFiles = (location, files, ids, setFormData = false, resetFormData = false) => {
    return new Promise(async (resolve, reject) => {
        const metadata = {
            contentType: 'image/*'
        };

        const promises = [];

        for (let k = 0; k < files.length; k++) {
            // setUprogess(0);
            promises.push(new Promise((innerResolve, innerReject) => {
                const file = files[k];
                const id = ids[k];
                
                const imgname = stripSpecialChar(id) + file.name.slice(file.name.lastIndexOf("."));
                const storageRef = ref(storage, `${location}/${imgname}`);
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        // setUprogess(progress);
                        // switch (snapshot.state) {
                        //     case 'paused':
                        //         setUstate('Upload Paused');
                        //         break;
        
                        //     case 'running':
                        //         setUstate('Uploading...');
                        //         break;
        
                        //     default:
                        //         setUstate('Upload Stopped');
                        // }
                    },
                    (error) => {
                        // Handle specific upload error
                        innerReject(error);
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref)
                            .then((downloadURL) => {
                                innerResolve({ downloadURL, imgname }); // Resolve the inner promise when done
                            })
                            .catch((error) => {
                                // Handle error when getting the download URL
                                innerReject(error);
                            });
                    }
                );
            }));
        }

        // Wait for all promises to resolve
        Promise.all(promises)
        .then(() => {
            // All uploads completed successfully
            setFormData && setFormData(resetFormData);
            resolve(promises);
        })
        .catch((error) => {
            // Handle any errors during the upload process
            reject(error);
        });
    });
};

export function extractFileName(url) {
    const parts = url.split('/');
    const filenameWithExtension = parts[parts.length - 1];
    const decodedFilename = decodeURIComponent(filenameWithExtension);
    const filenameParts = decodedFilename.split('?');
    const filenamePath = filenameParts[0];
    const filename = filenamePath.slice(filenamePath.lastIndexOf("/") + 1);
    return filename;
}

export const deleteFiles = async (urls, folder = "") => {
    try {
        if(!await authRequired()){
            const error = {
                code: 401,
                message: "Access not Granted",
            }
            throw error;
        }
        if(typeof(urls) === "object"){
            for (let k = 0; k < urls.length; k++) {
                const url = urls[k];
                const name = extractFileName(url);
                const desertRef = ref(storage, folder === "" ? name : `${folder}/${name}`);
                await deleteObject(desertRef);
            }
        }else{
            const name = extractFileName(urls);
            const desertRef = ref(storage, folder === "" ? name : `${folder}/${name}`);
            await deleteObject(desertRef);
        }
        return true;
    } catch (error) {
        return error;
    }
}