import React from 'react'
import { FaCoins } from 'react-icons/fa6'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { amountFormatter, showSwal, updateRecordField } from '../AppManager';
import useAuthRedirect from '../components/AuthRedirect';

export default function UpdateForm() {
    const { id } = useParams();
    const { data, setData, info } = useOutletContext();
    const [student, setStudent] = React.useState({name: "", matric: "", paid: 0, debt: 0});
    const [formData, setFormData] = React.useState({add: ""});
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    useAuthRedirect();

    React.useEffect(() => {
        if(Object.keys(data).length !== 0){
            if (Object.hasOwnProperty.call(data, id)) {
                const student = data[id];
                setStudent(student);
            }else{
                showSwal("error", "No Record Found");
                navigate("/add/update");
            }
        }
    }, [data, id, navigate]);

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(old => ({ ...old, [name]: value }));
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            const total = parseFloat(student.level === "400" ? info.current.siwes : info.current.amount);
            const paid = parseFloat(student.paid);
            const debt = total - paid;
            const add = parseFloat(formData.add);
            if(add > debt){
                showSwal("error", "Excess New Payment");
                setLoading(false);
            }else if(add === 0){
                showSwal("error", "New Payment cannot be ₦0");
                setLoading(false);
            }else{
                const newpaid = paid + add;
                const newdebt = total - parseFloat(newpaid);
                const updated = { ...student, paid: newpaid.toString(), debt: newdebt.toString() };

                const updater = await updateRecordField(info?.current?.session, id, updated);
                if(updater === true){
                    setData(old => ({ ...old, [id]: updated }));
                    if(newdebt === 0){
                        showSwal("success", "Payment Complete");
                    }else{
                        showSwal("info", "Record Updated");
                    }
                    setFormData({add: ""});
                }else{
                    showSwal("error", "Update Failed");
                }
                setLoading(false);
            }
        } catch (error) {
            showSwal("error", "Update Failed");
            setLoading(false);
        }
    }

    return (
        <form className='updateform' onSubmit={handleSubmit} style={{marginTop: "10px"}}>
            <div>
                <p><strong>Name: </strong><span style={{textAlign: "right"}}> { student.name }</span></p>
                <p><strong>Matric: </strong><span> { student.matric.toUpperCase() }</span></p>
                <p><strong>Paid: </strong><span> { amountFormatter(student.paid) }</span></p>
                <p><strong>Outstanding: </strong><span> { amountFormatter(student.debt) }</span></p>
            </div>
            <div>
                <FaCoins />
                <input type="text" autocomplete="off" name="add" className="input" maxlength="8" minlength="4" placeholder="New Payment" pattern='^\d+(\.\d+)?$' required value={ formData.add } onChange={ handleChange } />
            </div>
            <input type="submit" disabled={ loading } value="update" className="button" />
        </form>
    )
}
