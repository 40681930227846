import React from 'react'
import { FaUserEdit } from 'react-icons/fa'
import { FaCoins, FaIdCard, FaUser, FaUserTag } from 'react-icons/fa6'
import { Link, useOutletContext } from 'react-router-dom'
import { capitalizeEachWord, extractMatricDigits, showSwal, updateRecordField } from '../AppManager';
import useAuthRedirect from '../components/AuthRedirect';

export default function Add() {
    const { data, setData, info } = useOutletContext();
    const [formData, setFormData] = React.useState({name: "", matric: "", level: "", paid: ""});
    const [loading, setLoading] = React.useState(false);

    useAuthRedirect();

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(old => ({ ...old, [name]: name === "name" ? capitalizeEachWord(value) : name === "matric" ? value.toUpperCase() : value }));
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            const mat = extractMatricDigits(formData.matric);
            if (Object.hasOwnProperty.call(data, mat)) {
                showSwal("error", "Record Exists");
                setLoading(false);
            }else{
                const total = formData.level === "400" ? info.current.siwes : info.current.amount;
                const debt = parseFloat(total) - parseFloat(formData.paid);
                if(debt < 0){
                    showSwal("error", "Excess Amount Paid");
                }else if(debt === parseFloat(total)){
                    showSwal("error", "Amount Paid cannot be ₦0");
                }else{
                    const status = debt === 0 ? "Complete" : "Incomplete";
                    const student = { ...formData, debt: debt.toString(), status };

                    const adder = await updateRecordField(info?.current?.session, mat, student);
                    if(adder === true){
                        setData(old => ({ ...old, mat: student }));
                        setFormData({name: "", matric: "", level: "", paid: ""});
                        showSwal("success", "Record Added");
                    }else{
                        showSwal("error", "An Error Occured");
                    }
                }
                setLoading(false);
            }
        } catch (error) {
            showSwal("error", "An Error Occured");
            setLoading(false);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <FaUser />
                <input type="text" autoComplete="off" name="name" className="input" maxLength="30" minLength="7" placeholder="Full Name" onChange={handleChange} value={formData.name} required />
            </div>
            <div>
                <FaIdCard />
                <input type="text" autoComplete="off" name="matric" className="input" maxLength="16" minLength="15" placeholder="Matric Number" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RuN)/(CPE|CPe|Cpe|cPE|cpE|cpe|cPe|CpE)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' onChange={handleChange} value={formData.matric} required style={{ textTransform: "uppercase" }} />
            </div>
            <div>
                <FaUserTag />
                <select name="level" onChange={handleChange} value={formData.level} style={{ textTransform: "initial" }} className="input" required>
                    <option disabled value="">Select Level</option>
                    <option value="100">100 Level</option>
                    <option value="200">200 Level</option>
                    <option value="300">300 Level</option>
                    <option value="400">400 Level</option>
                    <option value="500">500 Level</option>
                </select>
            </div>
            <div>
                <FaCoins />
                <input type="text" autoComplete="off" name="paid" className="input" maxLength="8" minLength="4" placeholder="Amount Paid" onChange={handleChange} value={formData.paid} pattern='^\d+(\.\d+)?$' required />
            </div>
            <input type="submit" value="add" id="submit" disabled={loading} className="button" />    
            <Link to="update" className="swaplabel">
                <FaUserEdit />
            </Link>
        </form>
    )
}
